@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.transcriberQueue th,
.transcriberQueue tr td {
  font-family: Helvetica, "Open Sans";
}

.transcriberQueue tr td {
  font-size: 13px !important;
}
.transcriberQueue tr td:nth-child(10) {
  padding-right: 30px !important;
}
.container {
  width: 100%;
  padding: 10px;
}

.card {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
}

.mediaModal-Background {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 3;
  display: flex;
  justify-content: center;
  vertical-align: center;
}

.mediaModal-Content {
  position: fixed;
  width: 50%;
  height: max-content;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.loading {
  font-weight: bold;
  display: inline-block;
  font-family: monospace;
  font-size: 20px;
  letter-spacing: 0;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
  color: blue;
}

#bars {
  height: 10px;
}

.bar {
  background: #e20074;
  bottom: 1px;
  height: 3px;
  position: absolute;
  width: 3px;
  animation: sound 0ms -800ms linear infinite alternate;
}

@keyframes sound {
  0% {
    opacity: 0.35;
    height: 3px;
  }
  100% {
    opacity: 1;
    height: 12px;
  }
}

.bar:nth-child(1) {
  left: 1px;
  animation-duration: 474ms;
}
.bar:nth-child(2) {
  left: 5px;
  animation-duration: 433ms;
}
.bar:nth-child(3) {
  left: 9px;
  animation-duration: 407ms;
}
.bar:nth-child(4) {
  left: 13px;
  animation-duration: 458ms;
}
.bar:nth-child(5) {
  left: 17px;
  animation-duration: 400ms;
}
.bar:nth-child(6) {
  left: 21px;
  animation-duration: 427ms;
}
.bar:nth-child(7) {
  left: 25px;
  animation-duration: 441ms;
}
.bar:nth-child(8) {
  left: 29px;
  animation-duration: 419ms;
}
.bar:nth-child(9) {
  left: 33px;
  animation-duration: 487ms;
}
.bar:nth-child(10) {
  left: 37px;
  animation-duration: 442ms;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

.transcriberQueue {
  margin-top: 15px;
}

.transcriberQueue .tableContainer {
  overflow-x: auto;
}

.transcriberQueue .tableContainer::-webkit-scrollbar {
  width: 0.5em;
  background-color: transparent;
}

.transcriberQueue .tableContainer::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 0.5em;
}

.transcriberQueue .tableRow:last-child td,
.transcriberQueue .tableRow:last-child th {
  border: 0;
}

.transcriberQueue .tableRow td {
  font-size: 18px !important;
  padding: 5px 10px !important;
  text-align: start !important;
}

.transcriberQueue tr:nth-of-type(odd) {
  background-color: #f3f3f3;
}
.transcriberQueue th {
  text-align: start !important;
}

.transcriberQueue td {
  font-size: 12px !important;
  height: 40px !important;
  padding: 5px !important;
  text-align: center !important;
  max-width: 1px;
}

.transcriberQueue tr {
  height: 60px !important;
}

@media screen and (max-width: 600px) {
  .container {
    padding: 0;
  }

  .card {
    padding-left: 10px;
    padding-right: 10px;
  }

  .refreshButton {
    font-size: 12px !important;
    font-weight: bold !important;
    padding: 10px !important;
  }
}
