/* *{
} */
.App {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #1a336e;
  padding: 0;
  margin:0;
}
.wordiblyApp {
  background-color: #1a336e;
  height: 200vh;
  padding: 0;
  margin:0;
  font-family: Poppins, sans-serif;
  overflow: hidden ;
}
.wordiblyFooter{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  bottom: 0;
}

#triangle-topleft {
  width: 100%;
  height: 100px;
  background-color: #141414;
  clip-path: polygon(0 100px, 100% 100px, 100% 0);
}

.wordiblyFooter a{
  color: inherit;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: normal;
  line-height: 20px;
}
.wordiblyFooter a:hover {
  color: #64E83E; 
}
.wordiblyDiagonalFooterContent {
  display: flex; 
  justify-content: space-between;
  box-sizing:content-box;
  gap: 150px;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  }

  .wordiblyFooterPanel h2{
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.25px;
    cursor: pointer;
  }
  .wordiblyFooterPanel h2:hover{
    color: blue
  }
.wordiblyFooterPanel{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:10px;
  list-style-type: none;
  padding: 0;
  color: white;
}
.wordiblyFooterPanel a {
  color: inherit;
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: normal;
  line-height: 20px;
  
}
.wordiblyFooterPanel li {
  margin-bottom: 5px;
}
.wordiblyFooterPanel a:hover {
  color: #64E83E; 
}
.gridPannelContainer{
  display: flex;
  justify-content: space-evenly;
  gap: 32px;
}
/* .gridPannel{
  border-color: red;
  border: solid 1px red;
} */
.wordiblyMainContainer{
  min-height: calc(100vh - 200px);
}
.Banner {
  background-image: url("./assets//img//banner.png");
}

.InvoicePreview > div {
  margin: 0 !important;
}

.body label {
  font-weight: bold !important;
  color: black !important;
  font-size: 20px !important;
}

.ContainerTransriberQueue {
  padding: 10px 20px;
  background-color: transparent;
}

.viewContainer {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  background-color: transparent;

  width: 95% !important;
  margin-bottom: 10px;
}

.formContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: flex-end !important;
  width: 100%;
  margin-bottom: 10px !important;
  padding: 0;
}

.formControl {
  margin-right: 5px !important;
}

.MuiInputBase-root::before {
  border-bottom: none !important;
}

.formContainer .input {
  width: 100% !important;
}

.formButton {
  margin-bottom: 5px !important;
  width: 49% !important;
}

.body {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: transparent;
  padding-bottom: 10px;
}

.profileForm {
  padding: 10px 20px 0px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
}

.noForm label {
  font-size: 18px !important;
}

.payHistoryForm {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.payHistoryForm input {
  padding-right: 35px;
}

.informationContainer div::before {
  display: none !important;
}

.tableContainer {
  overflow-x: auto;
  overflow-y: auto;
  margin-right: auto;
  margin-left: auto;
  margin: 10px;
  width: 670px;
  display: flex;
  justify-content: center;
  max-height: 500px;
}

.tableContainer th {
  overflow-x: auto;
  white-space: nowrap;
  overflow-wrap: break-word !important;
  text-align: center !important;
}

.formButton {
  width: 225px;
  height: 47px;
  margin-top: auto;
  margin-bottom: 0;
}

#selectLabel {
  font-size: 1rem !important;
  margin: 0 !important;
  position: absolute;
  top: -3px !important;
}

.dateSection {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  margin: 10px !important;
  align-items: center;
  padding-right: 20px;
  box-sizing: border-box;
  justify-content: start !important;
}

.tittle {
  margin-left: 50px;
}

.noForm {
  padding: 2px 30px 0px;
  width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.noFormBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;
  height: 100%;
  margin: 20px;
}

.informationContainer {
  width: 50%;
}

.buttonContainer button {
  width: 100%;
  height: 40px;
}

.sectionDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.historyContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0 !important;
  width: 100% !important;
}

#dates {
  justify-content: space-between !important;
}

.dateInput {
  display: flex !important;
  width: 100%;
  height: 100%;
  flex-direction: row;
}

.boxModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 400px;
  background-color: #fff;
  border-radius: 10px;
  z-index: 100;
}

.formModal {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 8px;
  height: 80%;
}

.buttonsModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 45px;
  padding: 0;
  height: 80px;
}

.folderChain {
  display: flex;
  flex-direction: row;
}

.folderChain h2 {
  color: #1A326E;
  cursor: pointer;
  font-weight: 300;
}

.folderChain h2:last-child {
  color: black;
  cursor: default;
}

@media screen and (max-width: 1350px) and (min-width: 780px) {
  .tittle {
    margin-left: 40px !important;
  }

  .noForm {
    width: 500px;
  }

  .tableContainer {
    width: 500px;
  }

  .auxDiv {
    min-width: 200px !important;
  }
}

.uploadFilesInputContainer {
  margin: auto !important;
  max-width: 250px !important;
  height: 250px !important;
  margin-bottom: 30px !important;
  box-shadow: 5px 5px 10px;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px !important;
}

.filesOptions {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.optionContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  box-sizing: border-box;
  align-items: center;
}

.options span {
  font-size: 14px;
}

.credit span {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.5px !important;
}

.infBody {
  display: flex;
  flex-direction: column !important;
}

.optionInf {
  margin-left: 28px !important;
  font-size: 12px !important;
}

.dragAndDrop {
  margin: auto !important;
  width: 250px !important;
  height: 250px !important;
  margin-bottom: 30px !important;
  padding: 20px;
  border: dashed 2px #1a336e;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dragAndDropUpload {
  width: 100%;
  height: 300px;
  border: dashed 2px #1a336e;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.insideFile {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-around !important;
  border: dashed 1px #d3d3d3;
  height: 100%;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.uploadButton {
  color: White !important;
  float: right !important;
  width: 75%;
  font-weight: bold !important;
}

.filesToUpload {
  width: 100%;
}

.fileName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.goToCheckoutContainer {
  display: flex;
  justify-content: space-Around;
  width: 100%;
}

.checkoutContainer {
  width: 283px;
  height: 150px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  box-sizing: border-box;
  align-self: flex-end;
  margin-bottom: 160px;
}

.fileItem {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  width: 100%;
  justify-content: space-around !important;
  border-top: 1px solid #d3d3d3;
  padding-top: 10px;
  padding-bottom: 10px;
}

.listOfFiles {
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}

.renameInput {
  border-radius: 5px;

  border: 1px solid #ccc;
  padding: 5px 10px;
}

.renameInput:focus {
  outline: 2px solid #1a336e;
}

.uploadFiles {
  background-color: #64E83E;
  color: #1A336E !important;
  transition: background-color 0.3s, color 0.3s;
  height: "54px";
  width: "250px";
  &:hover {
    background-color: #1A336E;
    color: white !important;
  }
}

.createFolderButtonContainer {
  margin-top: 10px;
}

.createFolderButtonContainer button {
  color: white !important;
}

.clientBody label {
  font-weight: bold !important;
  color: black !important;
  font-size: 20px !important;
}

.contentContainer {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  height: 100%;
  overflow: auto;
  background-color: #f4f4f4;
  padding: 10px;
  box-sizing: border-box;
}

.knowledgeList {
  width: 320px;
  flex-shrink: 0;
  overflow: auto;
}

.content {
  width: 100%;
}

.activeKnow {
  background-color: #1a336e !important;
  color: white !important;
}

.clientBody {
  display: flex;
  padding: 30px;
}

.clientBody form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
  flex-grow: 1;
}

.clientBody .ClientButtonContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.clientBody .ClientButtonContainer button {
  color: white !important;
  width: 50% !important;
  margin: 10px !important;
  font-weight: bold;
}

.noFormClient {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  padding: 25px 50px;
}

.noFormClient button {
  height: 40px;
}

.listText {
  font-weight: 400 !important;
  font-size: 15px;
  margin-left: 20px;
}

.active {
  border-right: 2px solid #1a336e !important;
}

.active .listText {
  font-weight: 600 !important;
}

#mainContainer {
  transition-property: margin;
  transition-duration: 0.2s;
}
.mobileContainer {
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100%;
}

.mediumContainer {
  margin-left: 20px !important;
  margin-right: 20px !important;
  width: 100%;
}

.sideBarTrue {
  margin-left: 40px;
  margin-right: 40px;
  /* width: calc(100% - 300px); */
}

.sideBarFalse {
  width: 100%;
}

.clientFlexContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  padding: 0 !important;
}

.clientAppContainer {
  margin-top: 80px;
  background-color: white;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 250px;
}

.datePicker {
  padding: 0 !important;
  max-height: 100px !important;
}

.transactionSelect {
  height: 30px !important;
  width: 80px;
  padding: 0 !important;
  margin-right: 10px;
  text-overflow: none !important;
  overflow: hidden !important;
  font-size: 13px !important;
}

.transactionToolbar,
.transactionToolbarSidebar {
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
}

.datePicker input {
  padding: 5px !important;
  max-width: 100px !important;
}

.toolbarLabel {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.toolbarButton {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.orderNumber {
  cursor: pointer !important;
}

.informationDiv {
  display: flex;
  flex-direction: column;
  min-height: 140px;
  width: 250px;
  word-break: break-all;
  margin-top: 40px !important;
  margin: 5px;
  align-items: flex-start;
}

.informationDiv span {
  font-weight: 400 !important;
  font-size: 14px;
}

.informationDiv .informationBody {
  line-height: 22px !important;
  word-break: break-word; 
}

.invisibleDivider {
  border-color: white !important;
  margin: 3px !important;
}

.informationIcon {
  font-size: 23px !important;
  margin-bottom: 10px;
  margin-left: 5px;
}

.inviteUserForm {
  display: flex;
  justify-content: space-between;
  width: 700px;
  align-items: flex-end;
}

.usersToolbar {
  display: flex;
  justify-content: space-between;
}

.progress {
  position: relative;
  min-width: 140px;
  max-width: 140px;
  background: white;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  margin: auto;
}

.progress__fill {
  width: 0%;
  height: 100%;
  background: #c0b4bc;
  transition: all 0.2s;
  position: absolute;
  top: 0;
  left: 0;
}

.optionCost {
  white-space: nowrap;
}

.progress__text {
  position: relative;
  z-index: 1;
  font-size: 12px;
}

.paymentCard {
  padding: 1rem;
  width: 610px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
  border-radius: 5px;
  margin-top: 0 !important;
  margin-right: 40px;
}

.orderContainer {
  width: 610;
  height: 150;
  margin-right: 40px;
  padding: 30px;
  display: flex;
  justify-content: flex-end;
}


.orderContainer button {
  padding: 10px 20px;
}

.filesDetails {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
  border-radius: 5px;
  width: 300px;
  min-height: 413px;
}

.filesDetails .item {
  display: flex;
  justify-content: space-between;
  padding: 30px 15px;
}

.filesDetails .item span {
  font-size: 13px;
  letter-spacing: 2px;
}

.filesDetails .file {
  padding: 20px 10px;
}

.filesDetails .check {
  padding: 13px 15px;
}

.filesDetails .value {
  font-weight: bold;
}

.cardsContainer {
  display: flex;
  padding: 10px;
  width: 130px;
  justify-content: space-between;
}

.inputsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  box-sizing: border-box;
}

.cardsContainer .cardIcon {
  font-size: 24px;
}

.basicInformationCell {
  text-transform: capitalize;
  font-size: 18px !important;
  font-weight: 300 !important;
}

.basicInformationForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 700px;
  margin-right: auto;
}

.unitNameContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.unassignButton {
  color: white !important;
  font-size: 12px !important;
  height: 30px;
  margin-left: 10px !important;
}

.rejectButtonsContainer {
  display: flex;
  justify-content: flex-end;
}

.rejectButtonsContainer button {
  color: white !important;
  margin-right: 10px !important;
}

.transcriberHotkeysModal {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 500px !important;
  border: 2px solid black !important;
  border-radius: 4px;
}

.hotKeysButtonsCont {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 10px;
  padding: 5px;
  margin-bottom: 10px;
}

.unitHotkeys {
  width: 370px;
  padding: 10px !important;
  box-sizing: border-box !important;
}

.transcriberTabsMainContainer {
  background-color: #1A336E;
  color: white;
}

.transcriberTabsContainer {
  margin-top: 10px;
}

.transcriberTabs {
  font-size: 18px !important;
}

@media screen and (max-width: 1200px) and (min-width: 780px) {
  .tittle {
    margin-left: 30px !important;
  }

  .profileForm {
    width: 400px;
  }

  .formButton {
    width: 49% !important;
    font-size: 13px;
  }

  .dateSpecial {
    width: 338px !important;
  }
}

.timeForm {
  display: flex;
  align-items: center;
}

.rejectModal {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 500px !important;
  border: 2px solid #000 !important;
  box-shadow: 24 !important;
}

.reassignFormItem {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
}

.reassignFormItem span {
  margin-right: 20px;
}

.reassingModal {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 650px !important;
  border: 2px solid #000 !important;
  box-shadow: 24 !important;
  background-color: #fff !important;
}

.assignModal {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 650px !important;
  border: 2px solid #000 !important;
  box-shadow: 24 !important;
  background-color: #fff !important;
  padding: 20px !important;
}

.reasingnModalButtonsContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  box-sizing: border-box;
}

.sendBackModal {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 650px !important;
  border: 2px solid #000 !important;
  box-shadow: 24 !important;
  background-color: #fff !important;
  padding: 20px !important;
}
.navbarLogo{
  height: 54px;
}

@media screen and (max-width: 1172px) {
  .transactionToolbarSidebar {
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start;
  }

  .transactionToolbarSidebar .timeForm,
  .transactionToolbarSidebar .export {
    margin-right: auto !important;
    margin-bottom: 5px !important;
  }
}

@media screen and (max-width: 1032px) {
  .transactionToolbarSidebar .timeForm {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .transactionToolbarSidebar .timeForm div {
    margin-bottom: 12px;
  }
  .navbarLogo{
    height: 35px;
  }
}

@media screen and (max-width: 856px) {
  .transactionToolbar {
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start;
  }

  .transactionToolbar .timeForm,
  .transactionToolbar .export {
    margin-right: auto !important;
    margin-bottom: 5px !important;
  }
}

@media screen and (max-width: 685px) {
  .transactionToolbar .timeForm {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .transactionToolbar .timeForm div {
    margin-bottom: 5px;
  }
}

.checkBox {
  display: none !important;
}

.parent:hover .checkBox {
  display: block !important;
}

.filesIcon {
  margin-right: 10px;
}

#downloadButton:hover>#downloadIcon {
  color: #00b445 !important;
}

#moveButton:hover>#moveIcon {
  color: #ffb42a !important;
}

#deleteButton:hover>#deleteIcon {
  color: #ff3e39 !important;
}

.accountItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.emailForm {
  width: 400px;
}

@media screen and (max-width: 1162px) {
  .goToCheckoutContainer {
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
  }

  .checkoutContainer {
    margin-top: 0 !important;
    margin-bottom: 20px;
    margin-right: 100% !important;
    margin-left: 66% !important;
  }

  .listOfFiles {
    margin: 0 !important;
  }
}

@media screen and (max-width: 1100px) and (min-width: 780px) {
  .tittle {
    margin-left: 25px !important;
  }

  .content {
    display: none;
  }

  .knowledgeList {
    width: 280px;
  }

  .auxDiv {
    min-width: 140px !important;
  }

  .historyContainer * {
    font-size: 10px !important;
  }

  .noForm {
    width: 420px;
  }

  .tableContainer {
    width: 400px;
  }

  .sectionDiv * {
    font-size: 14px !important;
  }

  .sectionDiv {
    width: 50% !important;
  }
}

@media screen and (max-width: 1000px) and (min-width: 780px) {
  .profileForm {
    width: 300px;
  }

  .dateSection p {
    width: 100px !important;
  }
}

@media screen and (max-width: 937px) and (min-width: 780px) {
  .noForm {
    width: 400px;
  }

  .tableContainer {
    width: 350px;
  }

  .sectionDiv {
    width: 40% !important;
  }

  .sectionDiv * {
    font-size: 12px !important;
  }

  .filesToUpload {
    width: 70% !important;
  }

  .checkoutContainer {
    margin-top: 0 !important;
    margin-bottom: 20px;
    margin-right: 100% !important;
    margin-left: 45% !important;
  }
}

.myAccount {
  width: "500px !important";
}

@media screen and (max-width: 780px) {
  .tittle {
    margin-left: 10px !important;
  }

  .basicInformationForm {
    width: 100% !important;
    margin: 0;
  }

  .myAccount {
    width: 100% !important;
  }

  .auxDiv {
    min-width: 250px !important;
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .checkoutDescription {
    width: 100%;
  }

  .profileForm {
    width: 100%;
  }

  .noForm {
    width: 100%;
  }

  .formContainer {
    justify-content: space-between;
  }

  .historyContainer button {
    font-size: 12px;
  }

  .sectionDiv p {
    width: 168px;
  }

  .informationContainer {
    margin-right: 20px !important;
  }

  .buttonContainer {
    width: 100% !important;
    height: 144px !important;
    display: flex !important;
    margin: 0 !important;
  }

  .filesToUpload {
    width: 80% !important;
  }
}

@media screen and (max-width: 690px) {
  .noForm {
    width: 100%;
  }

  .usersToolbar .toolbarLabel {
    display: none !important;
  }

  .tableContainer {
    width: 100%;
  }

  .noFormBody {
    height: 100%;
  }
}

@media screen and (max-width: 550px) {
  .historyContainer * {
    font-size: 10px !important;
  }

  .checkoutContainer {
    margin-right: 100% !important;
    margin-left: 100% !important;
  }

  .inviteUserForm {
    width: 100%;
  }

  .sectionDiv p {
    width: 121px;
  }

  .boxModal {
    width: 300px;
  }

  .formContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    margin: 0;
  }

  .formButton {
    width: 100% !important;
  }

  .filesToUpload {
    width: 90% !important;
  }

  .rejectModal {
    width: 80% !important;
  }

  .transcriberHotkeysModal,
  .gridItem {
    width: 96% !important;
  }

  #payonnerForm {
    width: 100% !important;
  }

  .paymentInfo {
    width: 100% !important;
  }

  .noFormBody {
    flex-direction: column;
  }
}

@media screen and (max-width: 460px) {
  .noForm * {
    font-size: 10px !important;
  }

  .sectionDiv label {
    font-size: 16px !important;
  }

  .historyContainer * {
    font-size: 9px !important;
  }

  .sectionDiv p {
    width: 113px !important;
  }

  .filesToUpload {
    width: 95% !important;
  }

  .emailForm {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .boxModal {
    width: 250px;
  }

  .noForm button p {
    font-size: 9px !important;
  }

  .dateInput {
    width: 100% !important;
    display: flex;
  }

  .historyContainer {
    display: block;
    width: 100% !important;
  }

  .historyContainer button p {
    width: 100% !important;
    font-size: 12px !important;
  }

  .historyContainer .downloadIcon {
    font-size: 16px !important;
  }

  .informationContainer {
    margin-right: 50px !important;
  }

  .unitNameContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .unassignButton {
    margin-left: 0 !important;
  }
}

.without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}

input[type="time"]::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: -10px;
}

.Tags {
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.CrossTalkTag {
  background-color: hsla(274, 53%, 37%, 0.75);
}

.InaudibleTag {
  background-color: hsla(205, 72%, 36%, .75) !important;
}

.audioHotKeys {
  color: black !important;
  font-weight: bold;
  font-size: 14px !important;
  margin-top: 10px;
}

.iconAudioHotKeys {
  margin-left: 20px !important;
  color: black !important;
}
.squarePaymentContainer {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100vh;
}
.editDueTimeContainer {
  position: absolute;
  background-color: white;
  padding-top: 0.5rem;
  left: 2%;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 250px;
  border-radius: 5px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  z-index: 1000;
}
.editDueTimeSubContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 190px;
}
.revokedTeamMember {
  background-color: #eeeeee;
  text-decoration: line-through;
}

.textAreaCustomAlert {
  width: 100%;
  border-radius: 12px 12px 0 12px;
  padding: 8px;
  box-sizing: border-box;
  box-shadow: 0px 14px -8px rgba(0, 0, 0, 0.8);
  resize: vertical;
  max-height: 200px;
}
.textAreaCustomAlert:hover {
  border: 1px solid #1a336e;
}
.textAreaCustomAlert:focus {
  outline: 2px solid #1a336e;
}


.highlightBarUnde {
  color: white;
  position: relative;
  text-decoration: none;
  margin-left: 1rem;
  text-transform: capitalize;
}

.highlightBarUnde::before{
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 8px;
  background-color: #ffdf00;
  bottom: 2px;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
  z-index: -1;
}
.highlightBarUnde:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.menu-container {
  position: relative;  
}

.menu-toggle {
  position: absolute;
  right: 17px; 
  top: 49%;
  transform: translate(0, -50%);
  height: 35px;
  width: 35px;
  background-color:  rgba(0, 0, 0, 0);
  border: none;
  &, &:hover {
    cursor: pointer;
  }
}

.menu-toggle-bar {
  display: block;
  position: absolute;
  margin-top: -1;
  right: 0;
  top:50%;
  width: 35px;
  height: 2px;
  border-radius: 4px;
  background-color: white;
  transition: all 0.3s ease;

  &.menu-toggle-bar--top {
    transform: translate(0, -8px);
  }
  &.menu-toggle-bar--middle {
  }
  &.menu-toggle-bar--bottom {
    transform: translate(0, 8px);
  }

  .nav-open & {
    &.menu-toggle-bar--top {
      transform: translate(0, 0) rotate(45deg);
    }
    &.menu-toggle-bar--middle {
      opacity: 0;
    }
    &.menu-toggle-bar--bottom {
      transform: translate(0, 0) rotate(-45deg);
    }
  }
}

.BarOptionResp {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 1.25px;
  line-height: 29px;
  padding: 0x;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  margin-left: 1rem;
} 
.wordiblySideBarLogout{
  font-size: 25px;
  font-style: 600;
  letter-spacing: 1.25px;
  padding: 0x;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  margin-left: 1rem;
  height: "54px";
  width: "100vw"; 
  background-color:#64E83E;
  color:#1A326E;
} 

.openSideBarCollapse {
  transition: transform 1s;
}

.openSideBarCollapse.plus {
  transform: rotate(0deg);
}

.openSideBarCollapse.cross {
  transform: rotate(45deg);
}
@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

.squareQuickCheckoutContainer{
  background: url(/public/material-ui-circular-progress.gif) no-repeat ;
}