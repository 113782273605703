.register {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-title {
  color: black;
  font-weight: 900;
}

.notice {
  margin-bottom: 20px;
}

.field {
  margin-bottom: 15px;
}

.label-required {
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 500;
  vertical-align: middle;
  color: #6e6f71;
  letter-spacing: .125em;
  line-height: 1.33;
  padding-bottom: 0.25rem;
  color: #686470;
}

.input-block-level {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.input-block-level.readonly-input {
  background-color: #f7f7f7;
}

.flex {
  display: flex;
  align-items: center;
}

.rubik {
  font-family: 'Rubik', sans-serif;
}

.ttu {
  text-transform: uppercase;
}

.silver {
  color: silver;
  font-size: 12px;
}

.mv3-ns {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mv2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.account-creation-option {
  margin-bottom: 10px;
}

.button-red {
  background-color: red;
  color: white;
  padding: 10px 15px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.button-normal {
  font-size: 16px;
  font-weight: bold;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.grecaptcha-badge {
  width: 256px;
  height: 60px;
  box-shadow: gray 0px 0px 5px;
}

.grecaptcha-logo {
  display: inline-block;
  margin-bottom: -1px;
}

.grecaptcha-error {
  margin: 10px 25px;
  padding: 0;
  resize: none;
  display: none;
}

.sign-in-link {
  text-decoration: none;
  color: #1A336E;
}

.prepaidButton {
  background-color: #1A336E;
  color: white;

}

.prepaidButtonDisable {
  cursor: not-allowed;
  background-color: #ccc;
}

.completedOrderStatus {
  padding: 0 4px 0 4px;
  border-radius: 4px;
  background-color: black;
  text-align: center;
  color: white;
}
.AppFinished {
  width: auto!important;
  text-align: center;
  font-family: sans-serif;
  color: #333;
}

.animated {
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

.bounceInDown {
  animation-name: bounceInDown;
}

.fadeIn {
  animation-name: fadeIn;
}

.zoomIn {
  animation-name: zoomIn;
}

.shake {
  animation-name: shake;
}

@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  60% {
    opacity: 1;
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }

  20%,
  60% {
    transform: translateX(-10px);
  }

  40%,
  80% {
    transform: translateX(10px);
  }
}
